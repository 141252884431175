/* global $ */

class App
{
    miniCart;
    currencySwitcher;

    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initMiniCart();
        this.initCurrencySwitcher();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initMiniCart()
    {
        import(/* webpackChunkName: "minicart" */ './components/mini-cart').then(miniCart => {
            this.miniCart = new miniCart.MiniCart('#cart-modal');
        });
    }

    initCurrencySwitcher()
    {
        import(/* webpackChunkName: "currency-switcher" */ '@Ecomms/src/js/components/currency-switcher').then(switcher => {
            this.currencySwitcher= new switcher.CurrencySwitcher('#currency-switcher');
        });
    }

    initForms()
    {
        if ($('form.js-validate').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(form => {
                $.each($('form.js-validate'), (i, elem) => {
                    new form.Form(elem.id);
                });
            });
        }
    }

    initToasts(elems)
    {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems)
    {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }
}

window.App = new App;